.region-selector-modal-content .region-container:not(:last-child){
    padding-bottom: 16px;
}

.region-container {
    height: 66px;
}

.region-container>div>div>div:nth-child(2)>div>div {
    padding-bottom: 0;
}

.region-container>div>div>div:first-child {
    padding-top: 0;
}

.region-container>div>div {
    align-items: center;
}

.initials {
    color: #666666;
    background: #EEEEEE;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    padding: 8px;
    border-radius: 15px;
}

#region-selector-modal {
    border-width: 0;
    border-bottom-width: 1px;
}