.lastVisitedAppName {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  margin-top: 0px;
}

.lastVisitedPagesContainer {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 2rem;
}

.lastVisitedLabelContainer {
  margin-right: 1.5rem;
}

.lastVisitedContainer {
  overflow: auto;
}

@media (max-width: 425px) {
  .lastVisitedPagesCards {
    grid-template-columns: auto;
  }

  .large-card-row {
    display: none;
  }

  .medium-card-row {
    display: none;
  }

  .small-card-row {
    display: block;
  }
}

@media (min-width: 425px) {
  .lastVisitedPagesCards {
    grid-template-columns: auto auto;
  }

  .large-card-row {
    display: none;
  }

  .medium-card-row {
    display: none;
  }

  .small-card-row {
    display: block;
  }
}

@media (min-width: 1000px) {
  .lastVisitedPagesCards {
    grid-template-columns: auto auto auto;
  }

  .large-card-row {
    display: none;
  }

  .medium-card-row {
    display: none;
  }

  .small-card-row {
    display: block;
  }
}

@media (min-width: 1440px) {
  .lastVisitedPagesCards {
    grid-template-columns: auto auto auto auto;
  }

  .large-card-row {
    display: none;
  }

  .medium-card-row {
    display: none;
  }

  .small-card-row {
    display: block;
  }
}

@media (min-width: 1780px) {
  .lastVisitedPagesCards {
    grid-template-columns: auto auto auto auto auto;
  }

  .small-card-row {
    display: none;
  }

  .medium-card-row {
    display: block;
  }

  .large-card-row {
    display: none;
  }
}

@media (min-width: 2120px) {
  .lastVisitedPagesCards {
    grid-template-columns: auto auto auto auto auto auto;
  }

  .small-card-row {
    display: none;
  }

  .medium-card-row {
    display: none;
  }

  .large-card-row {
    display: block;
  }
}

.lastVisitedPagesCards {
  display: grid;
  gap: 1.5rem;
  justify-content: start;
}

.nameAndButtonSection {
  display: flex;
  justify-content: space-between;
  padding-bottom: 4px;
}

.homePageTitleLabel {
  font-weight: 700 !important;
  font-size: 28px !important;
  line-height: 36px !important;
  padding: 24px 0px 6px 24px !important;
}

.homePageSubtitleLabel {
  font-size: 13px !important;
  line-height: 20px !important;
  padding-left: 24px !important;
  padding-bottom: 42px !important;
}

.lastVisitedLabel {
  font-size: 18px !important;
  line-height: 28px !important;
  padding-left: 24px !important;
}

.seeAllButton {
  padding: 6px 12px !important;
}

.selectSection {
  display: flex;
  justify-content: space-between;
  margin-right: 1.5rem;
  margin-bottom: 42px;
}

.k-animation-container .k-list .k-list-item {
  box-shadow: none;
}

.k-list-item.k-selected {
  background-color: transparent !important;
  color: #222222 !important;
  font-weight: 600;
}

.k-list-item.k-focus {
  background-color: transparent !important;
}

.k-list-item:hover {
  background-color: #e6f1f6 !important;
  color: #222222 !important;
  border-radius: 2px;
}

.k-list-item.k-selected:hover {
  background-color: #e6f1f6 !important;
  color: #222222 !important;
  border-radius: 2px;
}

.k-list-item.k-focus:hover {
  background-color: #e6f1f6 !important;
  color: #222222 !important;
  border-radius: 2px;
}

.k-list-item:active {
  background-color: #cce3ec !important;
}

.k-list-item.k-selected:active {
  background-color: #cce3ec !important;
}

.k-list-item.k-focus:active {
  background-color: #cce3ec !important;
}