.wrapper {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 57px;
}

.child-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
}

.container {
  flex-direction: column;
  display: flex;
  background-color: #f6f6f6;
  overflow: auto;
  order: 1;
  flex: 1;
  padding: 16px, 32px, 16px, 32px;
}

.footer {
  height: 51px;
  background-color: white;
  order: 2;
}

.popout-container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.breadcrumb {
  background: #fff;
  height: 50px;
  padding: 0px 22px;
  order: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}