.tenant-switcher-popup-wrapper {
    display: flex;
    position: absolute;
    width: 296px;
    padding: 0px;
    right: 110px;
    top: 60px;
    border-radius: 8px;
    box-shadow: 0px 8px 24px -2px rgba(0, 0, 0, 0.16);
    z-index: 99;
    background-color: #FFFFFF;
}

.tenant-switcher-popup {
    padding: 16px;
    width: 100%;
}

.tenant-switcher-popup>div {
    width: 100%;
}

.tenant-switcher-popup>div>label {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.tenant-switcher-popup .k-listview-item {
    border-bottom: none !important;
    display: flex;
    padding-left: 8px;
}