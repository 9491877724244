.side-nav {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0px;
  width: 68px;
  z-index: 1030;
}

.side-nav-disabled {
  cursor: not-allowed;
  background-color: black;  
}

.side-nav-disabled > div{
  pointer-events: none;
  opacity: 0.5;
}