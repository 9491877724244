.k-window-titlebar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 16px 24px;
  border-width: 1px;
  border-style: solid;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
}

.k-justify-content-stretch > * {
  flex: 0 0 auto;
}
