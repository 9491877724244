.sandbox-button {
  background-color: #dddddd !important;
  border-radius: 12px !important;
  color: #005b7e !important;
  border-color: #dddddd !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 2px 8px !important;
}

svg.svg-inline--fa.fa-info.fa-lg {
  margin-bottom: 1px;
}

.product-name {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: default;
}