.unauthorizeElementsContainer {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.unauthorizeElementsContainer > svg {
  width: 140px;
  height: 140px;
}

.unauthorizedButtonGroup {
  display: flex;
  gap: 0.75rem;
}

.unauthorizedButtonGroup button {
  justify-content: center;
}

.unauthorizedFirstParagraph {
  margin-top: 40px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

.unauthorizedSecondParagraph {
  margin-top: unset;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.contactSalesButton {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #222222;
}
