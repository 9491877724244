.popup-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 320px;
    max-height: 572px;
    padding: 12px 16px 12px 16px;
    margin: 56px 12px;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0px 8px 24px -4px #00000029;
    z-index: 10000;
    border: 1px solid #DDDDDD;
    background-color: #FFFFFF;
}

.apps>.app-scroll-container {
    overflow-y: auto;
    width: 298px;
}

.app-scroll-container::-webkit-scrollbar {
    width: 8px;
}

.app-scroll-container::-webkit-scrollbar-track {
    background: #DDD;
    border-radius: 4px;
}

.app-scroll-container::-webkit-scrollbar-thumb {
    background: #BBB;
    border-radius: 4px;
}

.popup-header {
    width: 100%;
    padding: 0px 0px 8px 0px;
}

.home {
    display: flex;
    height: 46px;

    padding: 6px 12px 16px 12px;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
    gap: 16px;
    align-items: center;
    cursor: pointer;
}

.app-list {
    display: flex;
    max-height: 460px;
    width: 286px;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
}

.app-item {
    :hover {
        background: #F5F5F5;
    }
}

.hover-effect {
    background-color: #F5F5F5;
}

.app {
    display: flex;
    padding: 16px 40px 16px 16px;
    gap: 16px;
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    position: relative;
}

.product-info {
    width: 200px;
}

.circle {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #E3667F;
    position: absolute;
    top: -12px;
    right: -16px;
}

.pointer {
    cursor: pointer;
}

/* Additional styles for content within the popup */
.popup h2 {
    margin-top: 0;
}

.popup p {
    margin-bottom: 0;
}

label {
    padding: 0 !important;
}